import React from 'react'
import {
	footerContainer,
	detailContainer,
	connectContainer,
	socialConatainer,
	detailWrapper,
	forumImageContainer,
} from './styled'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import BrandIcon from 'components/brandIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Footer() {
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "icons/footer/bi-forum-logo.png" }) {
				childImageSharp {
					fixed(height: 165) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`)

	return (
		<footer className="footer" css={footerContainer}>
			<div css={detailWrapper}>
				<div css={detailContainer}>
					<div css={forumImageContainer}>
						<Img fixed={data.file.childImageSharp.fixed} />
					</div>
					<div
						className="has-text-centered has-text-white"
						css={connectContainer}
					>
						<span className="is-size-4 is-uppercase has-text-weight-bold">
							connect
						</span>
						<div css={socialConatainer}>
							<BrandIcon to="https://www.facebook.com/BrandInsideAsia/">
								<FontAwesomeIcon icon={['fab', 'facebook-f']} color="white" />
							</BrandIcon>
							<BrandIcon to="https://twitter.com/brandinsideasia">
								<FontAwesomeIcon icon={['fab', 'twitter']} color="white" />
							</BrandIcon>
						</div>
						<div>
							<span className="is-uppercase has-text-weight-bold">email</span>{' '}
							<a
								href="mailto:forum@brandinside.asia"
								className="has-text-white"
							>
								Forum@brandinside.asia
							</a>
						</div>
						<span>#BrandInsideForum2020</span>
					</div>
				</div>
			</div>
			<div className="content has-text-centered">
				<span className="has-text-white">
					© copyrights {new Date().getFullYear()} Blognone Co., Ltd.
				</span>
			</div>
		</footer>
	)
}
