export enum Screen {
	xxxs = 342,
	xxs = 576,
	xs = 767,
	sm = 991,
	md = 1199,
}

export const COLOR = {
	LIGHT_GREEN: '#66D91A',

	GREEN: '#609E38',

	YELLOW: '#FFCE3D',
	DARK_YELLOW: '#FFC228',

	YOUTUBE_RED: '#FF0200',
	TWITTER_LIGHT_BLUE: '#1DA1F2',
	LINKEDIN_LIGHT_BLUE: '#0077B5',
	FACEBOOK_BLUE: '#3B5998',

	BRAND_INSIDE_BLUE: '#428EEA',

	BLUE_1: '#567DB9',
	BLUE_2: '#36629E',
	BLUE_3: '#38649D',
	BLUE_4: '#32598C',
	BLUE_5: '#2D5285',
	BLUE_6: '#264770',
	BLUE_7: '#2C4560',
	BLUE_8: '#8DB2E1',
	BLUE_9: '#0080FF',

	TEAL: '#00587F',

	ORANGE: '#FF6445',

	RED_1: '#B22222',
	RED_2: '#721C24',

	PINK_1: '#F8D7DA',
	PINK_2: '#F5C6CB',

	WHITE: '#FFFFFF',
	WHITE_2: '#FAFAFA',
	WHITE_3: '#F5F5F5',
	WHITE_4: '#F1F1F1',
	WHITE_5: '#EAEAEA',

	GREY: '#E0E0E0',
	GREY_2: '#9E9E9E',
	GREY_3: '#F4F4F4',
	GREY_4: '#C4C4C4',
	GREY_5: '#DADADA',
	DARK_GREY: '#757575',
	DARK_GREY_2: '#424242',
	DARK_GREY_3: '#979797',
	DARK_GREY_4: '#474849',

	BLACK: '#333333',
	BLACK_2: '#171717',
	F_BLACK: '#000000',
}

export const FONT_SIZE = {
	large_9: '64px',
	large_8: '48px',
	large_7: '40px',
	large_6: '36px',
	large_5: '32px',
	large_4: '28px',
	large_3: '24px',
	large_2: '20px',
	large_1: '18px',
	normal: '16px',
	small_1: '14px',
	small_2: '12px',
	small_3: '10px',
	small_4: '8px',
}
