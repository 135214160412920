/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactNode } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import 'assets/styled.scss'
import Helmet from 'react-helmet'
import Header from 'components/header'
import Footer from 'components/footer'
import { bodyStyled } from './styled'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons'

interface Props {
	children?: ReactNode
}

const Layout = ({ children }: Props) => {
	library.add(faFacebookF, faTwitter)
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)

	return (
		<>
			<Helmet defaultTitle={data.site.siteMetadata.title}>
				<meta name="apple-mobile-web-app-capable" content="yes" />
				<meta
					name="apple-mobile-web-app-status-bar-style"
					content="black-translucent"
				/>
				<link
					href="https://fonts.googleapis.com/css?family=Montserrat:400,700|Prompt:300,700|Kanit:300,400,700&subset=thai&font-display=swap"
					rel="stylesheet"
				/>
			</Helmet>
			<Header />
			<div css={bodyStyled}>
				<main>{children}</main>
			</div>
			<Footer />
		</>
	)
}

export default Layout
