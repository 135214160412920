/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

interface Props {
	description?: string
	lang?: string
	meta?: HTMLMetaElement[]
	title: string
}

function SEO({ description = '', lang = 'en', meta = [], title }: Props) {
	const { site, ogImg } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						siteUrl
					}
				}
				ogImg: file(relativePath: { eq: "images/og.jpg" }) {
					publicURL
				}
			}
		`,
	)

	const metaDescription = description || site.siteMetadata.description

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={`${site.siteMetadata.title}`}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: 'og:url',
					content: `${site.siteMetadata.siteUrl}/newworkforce-2020`,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: 'og:image',
					content: `${site.siteMetadata.siteUrl}${ogImg.publicURL}`,
				},
				{
					name: 'twitter:url',
					content: `${site.siteMetadata.siteUrl}/newworkforce-2020`,
				},
				{
					name: `twitter:card`,
					content: `summary_large_image`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					name: 'twitter:image',
					content: `${site.siteMetadata.siteUrl}${ogImg.publicURL}`,
				},
				{
					name: 'twitter:site',
					content: site.siteMetadata.author,
				},
			].concat(meta)}
		/>
	)
}

export default SEO
