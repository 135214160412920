import React, { ReactNode } from 'react'
import { iconBadgeStyled } from './styled'

interface Props {
	to: string
	children?: ReactNode
}

function BrandIcon({ children, to }: Props) {
	return (
		<a
			href={to}
			target="_blank"
			rel="noopener noreferrer"
			className="button is-dark"
			css={iconBadgeStyled}
		>
			<span className="icon is-small">{children}</span>
		</a>
	)
}

export default BrandIcon
