import React, { useState, useCallback } from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql, Link } from 'gatsby'
import cn from 'classnames'
import {
	borderButtonStyled,
	navbarMenuStyled,
	navbarContainerStyled,
} from './styled'
import scrollTo from 'gatsby-plugin-smoothscroll'

export default function Header() {
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "icons/bi-forum-icon.png" }) {
				childImageSharp {
					fixed(height: 28) {
						...GatsbyImageSharpFixed_withWebp
					}
				}
			}
		}
	`)

	const [collapsed, setCollapsed] = useState(true)

	const toggleNavbar = useCallback(() => {
		setCollapsed(!collapsed)
	}, [collapsed])

	const jumpTo = useCallback((sectionName: string) => {
		return (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			e.preventDefault()
			setCollapsed(true)
			scrollTo(sectionName)
		}
	}, [])

	return (
		<nav
			className="navbar is-fixed-top"
			role="navigation"
			aria-label="main navigation"
		>
			<div className="container" css={navbarContainerStyled}>
				<div className="navbar-brand">
					<Link className="navbar-item" to="/">
						<Img fixed={data.file.childImageSharp.fixed} />
					</Link>
					<div
						className={cn('navbar-burger burger', { 'is-active': !collapsed })}
						aria-label="menu"
						aria-expanded="false"
						onClick={toggleNavbar}
					>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
					</div>
				</div>
				<div
					className={cn('navbar-menu', { 'is-active': !collapsed })}
					css={navbarMenuStyled}
				>
					<div className="navbar-end">
						<a
							role="button"
							className="is-uppercase navbar-item has-text-weight-bold"
							onClick={jumpTo('#about')}
						>
							about
						</a>
						<a
							role="button"
							className="is-uppercase navbar-item has-text-weight-bold"
							onClick={jumpTo('#agenda')}
						>
							agenda
						</a>
						<a
							css={borderButtonStyled}
							href="https://www.eventpop.me/e/8296"
							className="is-uppercase navbar-item has-text-weight-bold"
							target="_blank"
							rel="noopener noreferrer"
						>
							buy ticket
						</a>
					</div>
				</div>
			</div>
		</nav>
	)
}
