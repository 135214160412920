import { css } from '@emotion/core'
import { COLOR, Screen } from 'utils/mixins'

export const navbarContainerStyled = css`
	background-color: ${COLOR.WHITE};
`

export const borderButtonStyled = css`
	border: 1px solid ${COLOR.BLACK};
	margin: 12px;
`

export const navbarMenuStyled = css`
	@media (max-width: ${Screen.md}px) {
		position: absolute;
		left: 0;
		right: 0;
		display: block !important;
		transform: translateY(-150%);
		transition: transform 0.75s ease;
		z-index: -1;

		&.is-active {
			transform: translateY(0);
		}
	}
`
