import { css } from '@emotion/core'
import { COLOR, Screen } from 'utils/mixins'

export const footerContainer = css`
	padding-top: 66px;
	padding-bottom: 103px;
	background-color: ${COLOR.F_BLACK};
`

export const detailContainer = css`
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: repeat(2, 400px);

	@media (max-width: ${Screen.sm}px) {
		grid-template-rows: repeat(2, 1fr);
		grid-template-columns: 1fr;
	}
`

export const connectContainer = css`
	@media (min-width: ${Screen.sm + 1}px) {
		border-left: 1px solid ${COLOR.BRAND_INSIDE_BLUE};
	}

	@media (max-width: ${Screen.sm}px) {
		margin-top: 16px;
	}
`

export const socialConatainer = css`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`

export const detailWrapper = css`
	display: flex;
	justify-content: center;
	margin-bottom: 50px;
`

export const forumImageContainer = css`
	display: flex;
	justify-content: center;
`
